import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withParams = (Component) => {
        return props => <Component {...props} params={useParams()} location={useLocation()} navigate={useNavigate()} />;
}

export const orderArray = (array, orderParam) => {
    return array.sort((firstElement, nextElement) => firstElement[orderParam]-nextElement[orderParam]);
}

export default {
    orderArray,
    withParams
};