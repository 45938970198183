export const IMAGE_ALT = {
    HEADER_BLOG: 'Imagen de blog en cabecera',
    ROCKET: 'Imagen de cohete del inicio',
    TARGET: 'Imagen de objetivo',
    USER_IMAGE: 'Imagen usuario',
    FLAT_COLOMBIA: 'Bandera de Colombia',
    FLAT_ESTADOS_UNIDOS: 'Bandera de Estados Unidos',
    ICON_LANGUAGES: 'Icono de lenguas',
}
export const CONTENT_HEADER = {
    LINKS: {
        HOME: 'Inicio',
        BLOG: 'Blog',
        PROFILE: '¿Quién soy?',
        T_SHIRTS: 'Camisetas',
        LANGUAGES: 'Idioma',
        ENGLISH: 'Inglés',
        SPANISH: 'Español',
    }
}
export const CONTENT_FOOTER = {
    TITLES: {
        INFORMATION: 'Información:'
    },
    CONTENT_BODY: {
        BODY_INFORMATION_PART_I: 'Apasionado por la tecnología 💻, el desarrollo de software 👨🏽‍💻 y la docencia 👨🏽‍🏫. Ingeniero en sistemas y computación y Magister en ingeniería computacional de la Universidad de Caldas 🎓.',
        BODY_INFORMATION_PART_II: 'En esta página podrás encontrar educación en desarrollo de software 👨🏽‍💻📚, mi propósito es mejorar el mundo 🌎 creando mejor tecnología, ¡Acompañame a cumplirlo! Sigueme en mis redes sociales para mantenerte al tanto de mi contenido.'
    }
}

export const CONTENT_HOME_PAGE = {
    TITLES: {
        TITLE_WHY_I_DO_PART_I: '¿Porqué hago',
        TITLE_WHY_I_DO_PART_II: 'lo que hago? ',
        TITLE_HOW_I_DO_PART_I: '¿Cómo lo',
        TITLE_HOW_I_DO_PART_II: 'logro?',
        TITLE_WHAT_I_DO_PART_I: '¿Qué ',
        TITLE_WHAT_I_DO_PART_II: 'hago? ',

    },
    CONTENT_BODY: {
        BODY_WHY_I_DO: 'Considero que la tecnología es el canal perfecto para el avance de la humanidad, por ello me empeño en permitir que el software ayude a facilitar la vida de las personas y empresas que lo utilizan.',
        BODY_HOW_I_DO: 'Ayudando a la construcción y enseñanza del campo del desarrollo de software de calidad usando buenas prácticas y tecnologías demandadas del mercado.',
        BODY_WHAT_I_DO_PART_I: 'Construir software de calidad facilitando los procesos personales y empresariales.',
        BODY_WHAT_I_DO_PART_II: 'Enseñar a construir software y usar las herramientas y lenguajes más demandados del mundo del desarrollo de software.'
    }
}

export const CONTENT_PROFILE_PAGE = {
    TITLES: {
        TITLE_PERSONAL_PROFILE_PART_I: 'Carlos Andres',
        TITLE_PERSONAL_PROFILE_PART_II: 'Castañeda Osorio',
        TITLE_EXPERIENCE_ADDITIONAL_PROJECTS: 'Proyectos adicionales',
        TITLE_EXPERIENCE_JOBS: 'Experiencia laboral',
        TITLE_EXPERIENCE_STUDIES: 'Educación'
    },
    CONTENT_BODY: {
        BODY_PERSONAL_INFO_PART_I: 'Apasionado por la tecnología 💻, el desarrollo de software 👨🏽‍💻 y la docencia 👨🏽‍🏫. Ingeniero en sistemas y computación y Magister en ingeniería computacional de la Universidad de Caldas 🎓.',
        BODY_PERSONAL_INFO_PART_II: 'En esta página podrás encontrar educación en desarrollo de software 👨🏽‍💻📚, mi propósito es mejorar el mundo 🌎 creando mejor tecnología, ¡Acompañame a cumplirlo! Sigueme en mis redes sociales para mantenerte al tanto de mi contenido.'
    }
}
