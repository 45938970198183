import React, { Fragment, useContext, useEffect, useState } from "react";
import header_blog from '../assets/images/blog.svg';
import '../Styles/Blog.scss';
import ScrumEquipo from '../assets/images/scrumequipo.jpeg';
import ComoAprenderGit from '../assets/images/comoaprendergit.jpeg';
import DeCer9AApi from '../assets/images/deceroaapi.png';
import { orderArray } from "../utils/SharedUtils";
import { Link } from "react-router-dom";
import EntryItem from "../components/Blog/EntryItem";
import ReactGA from 'react-ga';
import languageFont from '../assets/language/index';
import LanguageContext from "../context/LanguageContext";
const Blog = () => {
    const [blogList, setBlogList] = useState([]);
    const { language } = useContext(LanguageContext);
    useEffect(() => {
        let response = [
            {
                id: 1,
                title: "Implementando SCRUM en una Startup",
                author: "Carlos Andrés Castañeda",
                author_profile_url: "https://ccastaneda.co",
                description: "Aumentar la velocidad del desarrollo de software es un reto constante que enfrentan las empresas en su día a día, sin importar el tamaño de la compañía, la velocidad y la conexión del equipo de trabajo son indicadores a mejorar en equipos de desarrollo de software. Las metodologías de desarrollo ágiles son un conjunto de marcos y prácticas basadas en los valores y principios expresados ​​en el manifiesto por el desarrollo de software ágil y los 12 principios detrás de él. Dentro de...",
                image_url: ScrumEquipo,
                date: "18/02/2023",
                commentary_count: 0,
                slug: "implementando-scrum-en-una-startup",
                order: 2,
                is_external_url: true,
                external_url: 'https://ccasta23.medium.com/implementando-scrum-en-una-startup-2b2bf21ff98'
            },
            {
                id: 2,
                title: "¿Por qué deberías aprender GIT?",
                author: "Carlos Andrés Castañeda",
                author_profile_url: "https://ccastaneda.co",
                description: "Si te encuentras entrando en el mundo del desarrollo de software, una de las preguntas más frecuentes será ¿Por qué debo aprender git?, en este post encontrarás la respuesta. Colaborar en el desarrollo de software de una aplicación con amigos o compañeros de trabajo es una labor demandante, cada persona avanza en el trabajo que le corresponde y, posteriormente, unir los cambios provocados a la base del código, puede tornarse en trabajo largo y tedioso. Para solucionar este problema...",
                image_url: ComoAprenderGit,
                date: "16/01/2023",
                commentary_count: 0,
                slug: "por-qué-deberías-aprender-git",
                order: 3,
                is_external_url: true,
                external_url: 'https://ccasta23.medium.com/por-qu%C3%A9-deber%C3%ADas-aprender-git-e2348e223556'
            } ,
            {
                id: 2,
                title: "De cero a API: Construyendo una API RESTFul con Laravel",
                author: "Carlos Andrés Castañeda",
                author_profile_url: "https://ccastaneda.co",
                description: "Por años el lenguaje de programación de PHP ha sido considerado una opción de desarrollo para la programación web, en el último tiempo se ha estigmatizado el uso de este lenguaje de programación dadás las malas prácticas implementadas por algunos desarrolladores que lo utilizan. En este post vamos a ver cómo el uso del Framework de Laravel nos permite usar las mejores prácticas del mercado usando el lenguaje...",
                image_url: DeCer9AApi,
                date: "24/03/2023",
                commentary_count: 0,
                slug: "de-cero-a-api-construyendo-una-api-restful-con-laravel",
                order: 1,
                is_external_url: true,
                external_url: 'https://ccasta23.medium.com/de-cero-a-api-construyendo-una-api-restful-con-laravel-6af78a4e70d1'
            }
        ]
        setBlogList(orderArray(response, 'order'));
        ReactGA.pageview('/blog');
    }, []);


    return (
        <main>
            <section id="blog" className="pb-4">
                <div className="blog__header-image">
                    <img src={header_blog} alt={languageFont[language].IMAGE_ALT.HEADER_BLOG}></img>
                </div>
                {
                    blogList.map((blogEntry, index) => {
                        return (
                            blogEntry.is_external_url ? (
                                <a className="entry-list" href={blogEntry.external_url} target="_blank">
                                    <EntryItem InfoEntry={blogEntry} currentIndex={index}>
                                    </EntryItem>
                                </a>
                            ) :
                                (
                                    <Link key={index} className="entry-list" to={'/blog/' + blogEntry.slug}>
                                        <EntryItem InfoEntry={blogEntry} currentIndex={index}>
                                        </EntryItem>
                                    </Link>
                                )
                        )
                    })
                }
            </section>
        </main>
    )
}

export default Blog;
