import React from 'react';
import banner from '../../assets/images/bann1_16-9.png';
import banner2 from '../../assets/images/bann2_16-9.png';
const Banner = () => {
    return (
        <div id="carouselExampleFade" className="carousel slide " data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="1" aria-label="Slide 2"></button>
                {/* <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
            </div>
            <div className="carousel-inner">
                {/* <div className="carousel-item active">
                    <img src={banner} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src={banner2} className="d-block w-100" alt="..." />
                </div> */}
                <div id="banner1" className="carousel-item active">
                    {/* <img src={banner} className="d-block w-100" alt="..." /> */}
                </div>
                <div id="banner2" className="carousel-item">
                    {/* <img src={banner2} className="d-block w-100" alt="..." /> */}
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
            </button>
        </div>
    )
}

export default Banner;