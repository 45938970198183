import React, { Fragment, useEffect, useState } from "react";
import { withParams } from "../../utils/SharedUtils";
import SkillBranch from '../../components/Profile/SkillBranch';
const SkillTree = (props) => {
    const [skillTree, setSkillTree] = useState(null);
    const [isTouched, setIsTouched] = useState(false);
    useEffect(() => {
        if (!skillTree) {
            setSkillTree(props.skillTree);
        }
    }, [props.skillTree]);

    const changeBranchOpenStatus = (branch) => {
        const newTree = [...skillTree];
        const newBranch = newTree.findIndex(oldBranch => oldBranch.id === branch.id);
        newTree[newBranch] = {
            ...newTree[newBranch],
            is_open: !newTree[newBranch].is_open
        };
        setSkillTree(newTree);
    }

    const printSkillsTree = () => {
        const treeBases = filterBases();
        return (
            treeBases.map(treeBase => (
                <div key={treeBase.id} className="skills__container__section accordion-father" >{
                    treeBase.is_open ? (
                        leafSearch(treeBase)
                    ) : (
                        <div className="d-flex align-items-center">
                            <SkillBranch onOpenSonView={changeBranchOpenStatus} dataSkill={treeBase}></SkillBranch>
                        </div>
                    )
                }</div>
            ))
        );
    }

    const leafSearch = (branch) => {
        const filteredBranches = filterBranches(branch.id);
        if (filteredBranches.length === 0) {
            return (
                <div className="d-flex align-items-center">
                    <hr className="skills__container__section__hr" />
                    <SkillBranch key={branch.id} dataSkill={branch} onOpenSonView={changeBranchOpenStatus} isLeaf={true}></SkillBranch>
                </div>
            );
        }
        const branchesArray = filteredBranches.map(filteredBranch => {
            return (
                <div
                    className="skills__container__section__sons self-accordion__item"
                    data-tree={filteredBranch.id}
                >
                    {
                        filteredBranch.is_open ? (
                            leafSearch(filteredBranch)
                        ) : (
                            <div className="d-flex align-items-center">
                                <SkillBranch dataSkill={filteredBranch} onOpenSonView={changeBranchOpenStatus}></SkillBranch>
                            </div>
                        )
                    }
                </div>
            )
        })
        branchesArray.push(
            <div className="d-flex align-items-center">
                {branch.fk_skill !== null ? <hr className="skills__container__section__hr" /> : ''}
                <SkillBranch dataSkill={branch} onOpenSonView={changeBranchOpenStatus}></SkillBranch>
            </div>
        );
        return branchesArray;
    }

    const filterBases = () => {
        return skillTree.filter(branch => branch.fk_skill === null);
    }
    const filterBranches = (branchFk) => {
        return skillTree.filter(branch => branch.fk_skill === branchFk);
    }

    return (
        <div className="self-accordion">
            {
                skillTree ?
                    printSkillsTree() : ''
            }
        </div>
    );
}

export default withParams(SkillTree);