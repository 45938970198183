import React, { useState } from "react";

const LanguageContext = React.createContext({
    language: 'ES'
})

export function LanguageContextProvider({ children }) {
    const [language, setLanguage] = useState('ES');
    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageContext;