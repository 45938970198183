import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import Blog from '../pages/Blog';
import Home from '../pages/Home';
import Profile from '../pages/Profile';
import Layout from './Layout';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import EntryDetail from './Blog/EntryDetail';
import {LanguageContextProvider} from '../context/LanguageContext';

library.add(fab)
function App() {

  function WithLayout() {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return (
    <LanguageContextProvider value={{language: 'ES'}}>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<WithLayout />} >
            <Route exact path='/' element={<Home />} />
            <Route exact path='blog' element={<Blog />} />
            <Route exact path='blog/:slug' element={<EntryDetail />} />
            <Route exact path='profile' element={<Profile />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LanguageContextProvider>
  );
}

export default App;
