import React, { Fragment, useEffect, useState } from "react";
import JobItem from "../../components/Profile/JobItem";
const MockJobs = [
    {
        "id": 1,
        "title": "Administrador de sistema de boletería",
        "start": "01/07/2013",
        "end": "10/05/2015",
        "company_name": "ONCE CALDAS SA",
        "company_url": "https://www.oncecaldas.com.co",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Administración y montaje de las taquillas para la venta de boletería para los espectáculos realizados por Once Caldas S.A.",
            "Montaje del sistema, realización de duplicados de boletas cuando la situación lo requiera, asistencia remota durante 40 partidos.",
            "Capacitación al personal de venta y al personal administrativo del club."
        ],
        "technologies": []
    },
    {
        "id": 2,
        "title": "Creación y administración del sistema de venta de abonos y de ingreso a los espectáculos",
        "start": "2014",
        "end": "2015",
        "company_name": "ONCE CALDAS SA",
        "company_url": "https://www.oncecaldas.com.co",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Levantamiento de requerimientos necesarios para crear el sistema acorde a las necesidades de la institución.",
            "Desarrollo del sistema de control de la información de abonos vendidos por el Once Caldas, manejados a través de tarjetas RFID, el sistema fue desarrollarlo en lenguaje JAVA con arquitectura MVC con base de datos en Postgresql.",
            "Transacciones totales manejadas por el sistema por cerca de $ 2.000’000.000",
            "Base de datos con un máximo de 14.000 usuarios abonados.",
            "Ingreso correcto por parte de los usuarios activos en el sistema de abonos al estadio Palogrande en 20 partidos."
        ],
        "technologies": [
            "Java SE",
            "Postgresql"
        ]
    },
    {
        "id": 3,
        "title": "Creación y administración del sistema integrado de boletería y abonos (SIBA)",
        "start": "2015",
        "end": "2016",
        "company_name": "ONCE CALDAS SA",
        "company_url": "https://www.oncecaldas.com.co",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Levantamiento de requerimientos necesarios para crear el sistema acorde a las necesidades de la institución.",
            "Desarrollo del sistema de integrado de boletería y abonos usado por el Once Caldas para la distribución y control de boletería y abonos, el sistema fue desarrollado en PHP, Javascript, Jquery, HTML, CSS, AJAX, bootstrap basado en la arquitectura MVC y con base de datos en Postgresql.",
            "Transacciones totales manejadas por el sistema por cerca de $ 1’300’000.000 en boletería.",
            "Base de datos con un máximo de 7.000 usuarios abonados y cerca de 71.000 boletas vendidas.",
            "Ingreso correcto por parte de los usuarios activos en el sistema de abonos al estadio Palogrande en 29 partidos."
        ],
        "technologies": [
            "HTML",
            "CSS",
            "Javascript",
            "JQuery",
            "PHP",
            "Postgresql"
        ]
    },
    {
        "id": 4,
        "title": "Ingeniero de Desarrollo",
        "start": "12/09/2016",
        "end": "27/06/2017",
        "company_name": "Newshore Servicios Globales",
        "company_url": "https://www.newshore.es",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Desarrollo de sistemas en Java EE.",
            "Despliegue de aplicaciones en Contenedor Weblogic y Websphere.",
            "Manejo básico de base de datos Oracle.",
            "Manejo de sistema operativo Linux"
        ],
        "technologies": [
            "Java EE",
            "Linux",
            "Oracle"
        ]
    },
    {
        "id": 5,
        "title": "Ingeniero de Software",
        "start": "01/11/2017",
        "end": "22/12/2017",
        "company_name": "Universidad de Caldas",
        "company_url": "https://www.ucaldas.edu.co/portal/",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Desarrollo correctivo del aplicativo PRODEXT estabilizando la versión que se encontraba operando.",
            "Actualización adaptativa de la aplicación PRODEXT para los requerimientos actuales de manejo de proyectos de la Vicerrectoria de Proyección, el sistema fue desarrollado en PHP, Javascript, Jquery, HTML, CSS, AJAX basado en la arquitectura MVC y con base de datos en Postgresql."
        ],
        "technologies": [
            "HTML",
            "CSS",
            "Javascript",
            "JQuery",
            "PHP",
            "Postgresql"
        ]
    },
    {
        "id": 6,
        "title": "Docente Ocasional",
        "start": "01/02/2020",
        "end": "31/06/2020",
        "company_name": "Universidad Nacional de Colombia",
        "company_url": "https://unal.edu.co",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Docente ocasional en materia lógica de programación haciendo uso del lenguaje de programación C#."
        ],
        "technologies": [
            "C#"
        ]
    },
    {
        "id": 7,
        "title": "Docente Catedrático",
        "start": "24/07/2017",
        "end": null,
        "company_name": "Universidad de Caldas",
        "company_url": "https://www.ucaldas.edu.co/portal/",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Docente catedrático en materias de programación en los programas de Ingeniería informática y tecnología en sistemas de información. Las materias dictadas son: Fundamentos de programación, POO, Estructuras de datos, Programación para entornos web, Análisis y diseño de sistemas de información I y II, nivelatorio de programación básica, nivelatorio de programación avanzada, Framework Laravel para desarrollo web."
        ],
        "technologies": [
            "Java",
            "PHP",
            "Laravel",
            "HTML",
            "CSS",
            "Javascript",
            "React",
            ".Net"
        ]
    },
    {
        "id": 8,
        "title": "Ingeniero de Desarrollo, CEO - Cofundador",
        "start": "12/09/2016",
        "end": null,
        "company_name": "Quick Ticket S.A.S",
        "company_url": "https://quickticket.com.co",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Levantamiento de requerimientos necesarios para crear el sistema acorde a las necesidades de la institución.",
            "Requerimiento no funcional de alta disponibilidad en donde se realiza un despliegue en Amazon Web Services basado en instancias EC2 y base de datos en RDS.",
            "Desarrollo del sistema de integrado de boletería y abonos usado por Quick Ticket S.A.S para la distribución y control de boletería y abonos, el sistema fue desarrollado en PHP, Javascript, Jquery, HTML, CSS, AJAX, bootstrap basado en la arquitectura MVC y con base de datos en Postgresql.",
            "Liderazgo del equipo de trabajo de la empresa"
        ],
        "technologies": [
            "HTML",
            "CSS",
            "Javascript",
            "JQuery",
            "PHP",
            "Postgresql"
        ]
    },
    {
        "id": 9,
        "title": "Chief Technology Officer (CTO)",
        "start": "01/07/2020",
        "end": "30/06/2021",
        "company_name": "Entu SAS",
        "company_url": "https://entu.com.co",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Líder encargado del área de tecnología de la compañía a cargo de 5 personas.",
            "Gestión del desarrollo del software de la compañía construido en Laravel y Angular",
            "Servicios Serverless (Lambda) escritos en Python y NodeJS.",
            "Liderazgo de proyecto sobre chatbot con código escrito en Javascript (NodeJS)"
        ],
        "technologies": [
            "Javascript",
            "Angular",
            "PHP",
            "Laravel",
            "Postgresql"
        ]
    },
    {
        "id": 10,
        "title": "Formador Misiontic",
        "start": "03/05/2021",
        "end": "21/07/2021",
        "company_name": "Universidad de Caldas",
        "company_url": "https://www.ucaldas.edu.co/portal/",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Entregar conocimientos sobre los fundamentos de la programación en el lenguaje Python. Ciclo 1 entre 03/05/2021 hasta 25/06/2021",
            "Desarrollo de retos y talleres con pruebas automatizadas",
            "Entregar conocimientos sobre Programación Básica que incluye el paradigma de programación orientada a objetos. Ciclo 2 entre 28/06/2021 hasta 21/07/2021"
        ],
        "technologies": [
            "Python",
            "C#"
        ]
    },
    {
        "id": 11,
        "title": "Acompañamiento en procesos de inducción, formación y metodología de trabajo en los procesos técnicos de la empresa y cultura empresarial",
        "start": "06/05/2021",
        "end": "31/12/2021",
        "company_name": "Cinnco",
        "company_url": "https://cinnco.co",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Analizar los procesos empresariales en busca de mejora de estos",
            "Recomendar rutas de aprendizaje personalizadas para cada persona del área de ingeniería para mejorar sus habilidades técnicas",
            "Recomendar rutas de aprendizaje personalizadas para cada líder de la empresa para mejorar tanto sus habilidades blandas como sus habilidades de proyectos",
            "Sugerir cambios en el propósito y valores de la compañía y reforzarlos ante todos los colaboradores"
        ],
        "technologies": [
            "Javascript",
            "Angular",
            "PHP",
            "Laravel",
            "Postgresql"
        ]
    },
    {
        "id": 12,
        "title": "Liderazgo del desarrollo de la página web Aguas de Manizales",
        "start": "30/08/2021",
        "end": "28/03/2022",
        "company_name": "Softwitz SAS",
        "company_url": null,
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Captura de requerimientos de parte del cliente que posteriormente se convierten en diseño e implementación de la página.",
            "Creación de la agenda del proyecto desde la fase de captura de requerimientos hasta la puesta en producción y posterior soporte de la aplicación.",
            "Acompañamiento técnico al equipo de desarrollo en el CMS de DNN.",
            "Acompañamiento y asignación de tareas al equipo de desarrollo."
        ],
        "technologies": [
            "DNN",
            "Git",
            "Github"
        ]
    },
    {
        "id": 13,
        "title": "Docente de clases en vivo",
        "start": "",
        "end": null,
        "company_name": "NextU",
        "company_url": "https://www.nextu.com",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "labors": [
            "Desarrollo de contenidos de clases en vivo.",
            "Dictar las clases en vivo dentro de la plataforma."
        ],
        "technologies": [
            "Git",
            "Github",
            "Laravel"
        ]
    }
];

const Jobs = () => {
    const [jobs, setJobs] = useState(null);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = () => {
        setJobs(MockJobs);
    }

    return (
        <ul>
            {
                jobs && jobs.map((job) => {
                    return <JobItem key={job.id} job={job}></JobItem>
                })
            }
        </ul>
    )
}

export default Jobs;