import React, { Fragment, useEffect, useState } from "react";
import AdditionalProjectItem from "../../components/Profile/AdditionalProjectItem";
const MockAdditionalProjects = [
    {
        "id_additional_project": 1,
        "additional_project_name": "Credeferias",
        "additional_project_description": "Sistema desarrollado para el control de credenciales de ingreso de los artesanos que participan en ferias artesanales",
        "additional_project_characteristics": [
            "Registro de artesanos con sus datos personales, generación de código de barras único y almacenamiento de fotografías.",
            "Registro de ingreso de artesanos al lugar del evento, el ingreso se realiza a través del código de barras y en el dispositivo se visualizan los datos personales, el stand donde se encuentra y su foto.",
            "Mostrar informes de datos agrupados.",
            "Despliegue en AWS con un servidor Linux EC2."
        ],
        "additional_project_creation_year": 2019,
        "additional_project_web_page": null,
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "technologies": [
            "HTML",
            "CSS",
            "Bootstrap",
            "Javascript",
            "JQuery",
            "PHP",
            "Postgresql"
        ]
    },
    {
        "id_additional_project": 2,
        "additional_project_name": "Geotour",
        "additional_project_description": "Este sistema fue desarrollado para el instituto de cultura y turismo de la ciudad de Manizales y proporciona una capa adicional a Google Maps con los sitios de mayor interés turístico de la ciudad,proporcionando rutas turísticas con información adicional sobre puntos como fotos, descripción e información del contacto.",
        "additional_project_characteristics": [
            "Visualización de los puntos turísticos más relevantes de la ciudad elegidos por quien administra el sistema, al utilizar la API de Google Maps, se pretende tener la mismas funcionalidades y añadir una capa de información que facilite la localización turística.",
            "Conexión con la API de Google Maps a través de Javascript.",
            "Sitio de administración de rutas y puntos.",
            "Despliegue en AWS con un servidor Linux EC2."
        ],
        "additional_project_creation_year": 2019,
        "additional_project_web_page": null,
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "technologies": [
            "HTML",
            "CSS",
            "Bootstrap",
            "Javascript",
            "JQuery",
            "PHP",
            "Postgresql"
        ]
    },
    {
        "id_additional_project": 3,
        "additional_project_name": "Agrodata",
        "additional_project_description": "Sistema de información para la gestión de datos de fincas cafeteras y ganaderas. Facilita el control de los tiempos de las plantaciones y el costo de nómina de la finca.",
        "additional_project_characteristics": [
            "Gestión de información básica de las fincas.",
            "Gestión de los datos de las plantaciones con gráficas para determinar tiempos de plantaciones y llevar el control de las fases por las que pasa.",
            "Gestión de la información de los trabajadores y sus labores asignadas por semana.",
            "Gestión de ingresos y egresos de las fincas."
        ],
        "additional_project_creation_year": 2020,
        "additional_project_web_page": null,
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "technologies": [
            "HTML",
            "CSS",
            "Bootstrap",
            "Javascript",
            "React",
            "Laravel",
            "Postgresql"
        ]
    },
    {
        "id_additional_project": 4,
        "additional_project_name": "Macrooster",
        "additional_project_description": "Página web para la gestión de la venta de productos de la cadena de restaurantes Macrooster.",
        "additional_project_characteristics": [
            "Gestión de los productos ofrecidos por la cadena de restaurantes.",
            "Gestión de usuarios con roles y permisos.",
            "Gestión de pedidos con flujo de estados.",
            "Informes agrupados para conocer la información de las ventas"
        ],
        "additional_project_creation_year": 2021,
        "additional_project_web_page": "https://macrooster.com",
        "city": "Manizales",
        "state": "Caldas",
        "country": "Colombia",
        "technologies": [
            "HTML",
            "CSS",
            "Bootstrap",
            "Javascript",
            "React",
            "Laravel",
            "Postgresql"
        ]
    }
];

const AdditionalProjects = () => {
    const [additionalProjects, setAdditionalProjects] = useState(null);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = () => {
        setAdditionalProjects(MockAdditionalProjects);
    }

    return (
        <ul>
            {
                additionalProjects && additionalProjects.map((additionalProject) => {
                    return <AdditionalProjectItem key={additionalProject.id_additional_project} additionalProject={additionalProject}></AdditionalProjectItem>
                })
            }
        </ul>
    )
}

export default AdditionalProjects;