import React, { Fragment, useContext, useEffect, useState } from "react";
import fondo from '../assets/images/computer.jpeg';
import user_img from '../assets/images/user_img.png';
import bandera_col from '../assets/images/bandera_col.svg';
import bandera_usa from '../assets/images/bandera_usa.svg';
import ico_idioma_banderas from '../assets/images/ico_idioma_banderas.svg';
import icons from "../components/Constants/iconsShortCut";
import '../Styles/Profile.scss';
import SkillTree from "../hooks/Profile/SkillTree";
import Jobs from "../hooks/Profile/Jobs";
import Studies from "../hooks/Profile/Studies";
import AdditionalProjects from "../hooks/Profile/AdditionalProjects";
import ReactGA from 'react-ga';
import languageFont from '../assets/language/index';
import LanguageContext from "../context/LanguageContext";
const Profile = () => {
    const { solidIcons } = icons;
    const { language } = useContext(LanguageContext);
    const [skillsTree, setSkillsTree] = useState([
        {
            "id": 1,
            "skill_name": "Habilidades técnicas",
            "fk_skill": null,
            "has_rating": false,
            "is_open": true
        },
        {
            "id": 2,
            "skill_name": "Habilidades blandas",
            "fk_skill": null,
            "has_rating": false,
            "is_open": false
        },
        {
            "id": 3,
            "skill_name": "Lenguajes de Programación",
            "fk_skill": 1,
            "has_rating": false,
            "is_open": true
        },
        {
            "id": 4,
            "skill_name": "Computación en la nube",
            "fk_skill": 1,
            "has_rating": false,
            "is_open": false
        },
        {
            "id": 5,
            "skill_name": "PHP",
            "fk_skill": 3,
            "has_rating": true,
            "skill_rating": 9,
            "is_open": false
        },
        {
            "id": 6,
            "skill_name": "Laravel",
            "fk_skill": 5,
            "has_rating": true,
            "skill_rating": 7,
            "is_open": false
        },
        {
            "id": 7,
            "skill_name": "Javascript",
            "fk_skill": 3,
            "has_rating": true,
            "skill_rating": 8,
            "is_open": false
        },
        {
            "id": 8,
            "skill_name": "React",
            "fk_skill": 7,
            "has_rating": true,
            "skill_rating": 7,
            "is_open": false
        },
        {
            "id": 9,
            "skill_name": "Angular",
            "fk_skill": 7,
            "has_rating": true,
            "skill_rating": 2,
            "is_open": false
        },
        {
            "id": 10,
            "skill_name": "Node",
            "fk_skill": 7,
            "has_rating": true,
            "skill_rating": 6
        },
        {
            "id": 11,
            "skill_name": "CSS",
            "fk_skill": 3,
            "has_rating": true,
            "skill_rating": 6,
            "is_open": false
        },
        {
            "id": 12,
            "skill_name": "C#",
            "fk_skill": 3,
            "has_rating": true,
            "skill_rating": 5,
            "is_open": false
        },
        {
            "id": 13,
            "skill_name": ".Net",
            "fk_skill": 12,
            "has_rating": true,
            "skill_rating": 2,
            "is_open": false
        },
        {
            "id": 14,
            "skill_name": "Python",
            "fk_skill": 3,
            "has_rating": true,
            "skill_rating": 7,
            "is_open": false
        },
        {
            "id": 15,
            "skill_name": "Tensorflow",
            "fk_skill": 14,
            "has_rating": true,
            "skill_rating": 5,
            "is_open": false
        },
        {
            "id": 16,
            "skill_name": "FastAPI",
            "fk_skill": 14,
            "has_rating": true,
            "skill_rating": 5,
            "is_open": false
        },
        {
            "id": 17,
            "skill_name": "Amazon Web Services",
            "fk_skill": 4,
            "has_rating": true,
            "skill_rating": 7,
            "is_open": false
        },
        {
            "id": 18,
            "skill_name": "CDK",
            "fk_skill": 17,
            "has_rating": true,
            "skill_rating": 4,
            "is_open": false
        },
        {
            "id": 19,
            "skill_name": "Serverless - Lambda",
            "fk_skill": 17,
            "has_rating": true,
            "skill_rating": 3,
            "is_open": false
        },
        {
            "id": 20,
            "skill_name": "Habilidades sociales",
            "fk_skill": 2,
            "has_rating": true,
            "skill_rating": 10,
            "is_open": false
        },
        {
            "id": 21,
            "skill_name": "Mentalidad de crecimiento",
            "fk_skill": 2,
            "has_rating": true,
            "skill_rating": 10,
            "is_open": false
        },
        {
            "id": 22,
            "skill_name": "Flexibilidad",
            "fk_skill": 2,
            "has_rating": true,
            "skill_rating": 8,
            "is_open": false
        },
        {
            "id": 23,
            "skill_name": "Compromiso",
            "fk_skill": 2,
            "has_rating": true,
            "skill_rating": 9,
            "is_open": false
        },
        {
            "id": 24,
            "skill_name": "Proactividad",
            "fk_skill": 2,
            "has_rating": true,
            "skill_rating": 9,
            "is_open": false
        }
    ]);

    useEffect(() => {
        ReactGA.pageview('/profile');
    }, []);
    const estructurePersonalInfo = () => {
        return <Fragment>
            {/* <img className="personal-info__image-background" src={fondo} alt="background image"></img> */}
            <section className="row personal-info__section">
                <div className="col-4 col-xxl-3 offset-1 d-flex justify-content-center">
                    <div className="card" >
                        <img src={user_img} className="card-img-top" alt={languageFont[language].IMAGE_ALT.USER_IMAGE}></img>
                        <div className="card-body">
                            <img src={ico_idioma_banderas} className="card-img-top" alt={languageFont[language].IMAGE_ALT.ICON_LANGUAGES}></img>
                            <img src={bandera_col} className="card-img-top" alt={languageFont[language].IMAGE_ALT.FLAT_COLOMBIA}></img>
                            <img src={bandera_usa} className="card-img-top" alt={languageFont[language].IMAGE_ALT.FLAT_ESTADOS_UNIDOS}></img>
                        </div>
                    </div>
                </div>

                <div className="col-7 col-xxl-8 personal-info__container">
                    <div className="col-11 offset-md-1 personal-info__title">
                        <h1>{languageFont[language].CONTENT_PROFILE_PAGE.TITLES.TITLE_PERSONAL_PROFILE_PART_I}</h1>
                        <h4>{languageFont[language].CONTENT_PROFILE_PAGE.TITLES.TITLE_PERSONAL_PROFILE_PART_II}</h4>
                    </div>
                    <div className="col-10 offset-1 personal-info__article__invisible">
                        <p>{languageFont[language].CONTENT_PROFILE_PAGE.CONTENT_BODY.BODY_PERSONAL_INFO_PART_I} <br/> {languageFont[language].CONTENT_PROFILE_PAGE.CONTENT_BODY.BODY_PERSONAL_INFO_PART_II}</p>
                    </div>
                </div>
                <div className="col-lg-6 col-10 offset-1 personal-info__article__visible">
                    <p>{languageFont[language].CONTENT_PROFILE_PAGE.CONTENT_BODY.BODY_PERSONAL_INFO_PART_I} <br/> {languageFont[language].CONTENT_PROFILE_PAGE.CONTENT_BODY.BODY_PERSONAL_INFO_PART_II}</p>
                </div>
            </section>
        </Fragment>
    }

    const estructureSkills = () => {
        return (
            <div className="row">
                <div className="col-12 col-md-11 col-lg-11 offset-md-1  skills__container ">
                    <SkillTree skillTree={skillsTree} ></SkillTree>
                </div>
            </div>
        )
    }

    const estructureExperience = () => {
        return (
            <Fragment>
                <div className="experience__section-container mt-0">
                    <h3>{languageFont[language].CONTENT_PROFILE_PAGE.TITLES.TITLE_EXPERIENCE_JOBS}</h3>
                    <Jobs></Jobs>
                </div>
                <div className="experience__section-container">
                    <h3>{languageFont[language].CONTENT_PROFILE_PAGE.TITLES.TITLE_EXPERIENCE_STUDIES}</h3>
                    <Studies></Studies>
                </div>
                <div className="experience__section-container">
                    <h3>{languageFont[language].CONTENT_PROFILE_PAGE.TITLES.TITLE_EXPERIENCE_ADDITIONAL_PROJECTS}</h3>
                    <AdditionalProjects></AdditionalProjects>
                </div>
            </Fragment>
        )
    }


    return (
        <main>
            <section id="personal-info" className="container-fluid personal-info" >
                {estructurePersonalInfo()}
            </section>
            <section className="container-fluid row ms-0">
                <section id="skills" className="container skills col-md-6">
                    {estructureSkills()}
                </section>
                <section id="experience" className="container experience col-md-6">
                    {estructureExperience()}
                </section>
            </section>
        </main>
    )

}

export default Profile;
