import React, { Fragment } from 'react'
import Header from './Header';
import Footer from './Footer';


const Layout = (props) => {
    const childrenWithProps = React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child);
        }
        return child;
    });

    return (
        <Fragment>
            <Header />
                {childrenWithProps}
            <Footer />
        </Fragment>
    )
}
export default Layout;