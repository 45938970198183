
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import iconsShortCut from '../Constants/iconsShortCut';

const EntryItem = (props) => {
    const [currentEntry, setCurrentEntry] = useState(null);
    const isEven = props.currentIndex % 2 === 0 ? true : false;
    const { solidIcons } = iconsShortCut;
    
    useEffect(() => {
        if (props.InfoEntry)
            setCurrentEntry(props.InfoEntry);
    }, []);
    return (
        <article className='container py-3 px-4'>
            {
                currentEntry ?
                    <Fragment>

                        {
                            isEven ?
                                (<div className='row entry-list__item-left'>
                                    <div className='col-4 p-0'>
                                        <img src={currentEntry.image_url} />
                                    </div>
                                    <div className='col-8 entry-list__item-left__paragraph'>
                                        <h4>{currentEntry.title}</h4>
                                        <p>{currentEntry.description}</p>
                                    </div>
                                </div >)
                                :
                                (<div className='row entry-list__item-right'>
                                    <div className='col-8 entry-list__item-right__paragraph'>
                                        <h4>{currentEntry.title}</h4>
                                        <p>{currentEntry.description}</p>
                                    </div>
                                    <div className='col-4 p-0'>
                                        <img src={currentEntry.image_url} />
                                    </div>
                                </div >)
                        }
                        < div className='row mt-2 entry-list__commentary'>
                            <em className='italics'>{currentEntry.author} - {currentEntry.date}</em>
                            <span >{currentEntry.commentary_count}<FontAwesomeIcon icon={solidIcons['faMessage']} /></span>
                        </div>
                    </Fragment>
                    :
                    ''
            }
        </article >
    );
}

export default EntryItem;