export const IMAGE_ALT = {
    HEADER_BLOG: 'Blog header image',
    ROCKET: 'Start rocket image',
    TARGET: 'Target image',
    USER_IMAGE: 'User image',
    FLAT_COLOMBIA: 'Colombian Flag',
    FLAT_ESTADOS_UNIDOS: 'USA Flag',
    ICON_LANGUAGES: 'Language icon',
}

export const CONTENT_HEADER = {
    LINKS: {
        HOME: 'Home',
        BLOG: 'Blog',
        PROFILE: 'Who I am?',
        T_SHIRTS: 'T-Shirts',
        LANGUAGES: 'Language',
        ENGLISH: 'English',
        SPANISH: 'Spanish',
    }
}

export const CONTENT_FOOTER = {
    TITLES: {
        INFORMATION: 'Information:'
    },
    CONTENT_BODY: {
        BODY_INFORMATION_PART_I: 'Apasionado por la tecnología 💻, el desarrollo de software 👨🏽‍💻 y la docencia 👨🏽‍🏫. Ingeniero en sistemas y computación y Magister en ingeniería computacional de la Universidad de Caldas 🎓.',
        BODY_INFORMATION_PART_II: 'En esta página podrás encontrar educación en desarrollo de software 👨🏽‍💻📚, mi propósito es mejorar el mundo 🌎 creando mejor tecnología, ¡Acompañame a cumplirlo! Sigueme en mis redes sociales para mantenerte al tanto de mi contenido.'
    }
}

export const CONTENT_HOME_PAGE = {
    TITLES: {
        TITLE_WHY_I_DO_PART_I: '¿Why do I do',
        TITLE_WHY_I_DO_PART_II: 'what I do? ',
        TITLE_HOW_I_DO_PART_I: '¿How do I',
        TITLE_HOW_I_DO_PART_II: 'do it?',
        TITLE_WHAT_I_DO_PART_I: '¿What do I ',
        TITLE_WHAT_I_DO_PART_II: 'do? ',
    },
    CONTENT_BODY: {
        BODY_WHY_I_DO: 'I consider technology to be the perfect conduit for humanity to progress, that is the reason why I develop software that benefits people and businesses.',
        BODY_HOW_I_DO: 'Aid in the advancement and teaching of the software development field by using good practices and highly sought after technologies.',
        BODY_WHAT_I_DO_PART_I: 'Build high quality software, facilitating personal and entreprenurial processes.',
        BODY_WHAT_I_DO_PART_II: 'Teach how to Build software and use the most sought after toolsets and technologies in the software development world.'
    }
}

export const CONTENT_PROFILE_PAGE = {
    TITLES: {
        TITLE_PERSONAL_PROFILE_PART_I: 'Carlos Andres',
        TITLE_PERSONAL_PROFILE_PART_II: 'Castañeda Osorio',
        TITLE_EXPERIENCE_ADDITIONAL_PROJECTS: 'Personal projects',
        TITLE_EXPERIENCE_JOBS: 'Work experience',
        TITLE_EXPERIENCE_STUDIES: 'Education'
    },
    CONTENT_BODY: {
        BODY_PERSONAL_INFO_PART_I: 'Apasionado por la tecnología 💻, el desarrollo de software 👨🏽‍💻 y la docencia 👨🏽‍🏫. Ingeniero en sistemas y computación y Magister en ingeniería computacional de la Universidad de Caldas 🎓.',
        BODY_PERSONAL_INFO_PART_II: 'En esta página podrás encontrar educación en desarrollo de software 👨🏽‍💻📚, mi propósito es mejorar el mundo 🌎 creando mejor tecnología, ¡Acompañame a cumplirlo! Sigueme en mis redes sociales para mantenerte al tanto de mi contenido.'
    }
}
