import React, { Fragment, useEffect, useState, useContext } from "react";
import '../Styles/Home.scss';
import Banner from "../components/Home/Banner";
import rocket from '../assets/images/cohete.svg';
import target from '../assets/images/como_logro.svg';
import fondo from '../assets/images/computer-what-i-do.jpeg';
import ReactGA from 'react-ga';
import languageFont from '../assets/language';
import LanguageContext from "../context/LanguageContext";


const Home = () => {
    const {language} = useContext(LanguageContext);
    useEffect(() => {
        ReactGA.pageview('/home');
    }, []);
    
    const estructureWhyIdo = () => {
        return <Fragment>
            <section className="row">
                <div className="col-md-6 col-12 why-I-do__title">
                    <h1>{languageFont[language].CONTENT_HOME_PAGE.TITLES.TITLE_WHY_I_DO_PART_I} </h1>
                    <h1 className="fw-bold">{languageFont[language].CONTENT_HOME_PAGE.TITLES.TITLE_WHY_I_DO_PART_II} </h1>
                </div>
                <div className="col-md-6 col-12 why-I-do__article">
                    <p>
                        {languageFont[language].CONTENT_HOME_PAGE.CONTENT_BODY.BODY_WHY_I_DO}
                    </p>
                </div>
            </section>
            <section className="row">
                <div className="col-12 col-md-6 offset-md-3 why-I-do__image">
                    <img src={rocket} alt={languageFont[language].IMAGE_ALT.ROCKET}></img>
                </div>
            </section>
        </Fragment>
    }

    const estructureHowIdo = () => {
        return <section className="row py-md-3">
            <section className="col-12 col-md-7">
                <div className=" col-12 how-I-do__title">
                    <h1>{languageFont[language].CONTENT_HOME_PAGE.TITLES.TITLE_HOW_I_DO_PART_I} <b>{languageFont[language].CONTENT_HOME_PAGE.TITLES.TITLE_HOW_I_DO_PART_II}</b> </h1>
                </div>
                <div className=" col-12 how-I-do__article">
                    <p>{languageFont[language].CONTENT_HOME_PAGE.CONTENT_BODY.BODY_HOW_I_DO}</p>
                </div>
            </section>
            <section className="col-12 col-md-5">
                <div className="how-I-do__image">
                    <img src={target} alt={languageFont[language].IMAGE_ALT.TARGET}></img>
                </div>
            </section>
        </section>
    }

    const estructureWhatIdo = () => {
        return <Fragment>
            <section className="row m-0 what-I-do__section-background">
                <div className="col-12 col-md-6 col-xl-5  what-I-do__title">
                    <h1>{languageFont[language].CONTENT_HOME_PAGE.TITLES.TITLE_WHAT_I_DO_PART_I}</h1>
                    <h1 className="fw-bold">{languageFont[language].CONTENT_HOME_PAGE.TITLES.TITLE_WHAT_I_DO_PART_II}</h1>
                </div>
                <div className="col-12 col-md-6 col-xl-5 what-I-do__article">
                    <p>{languageFont[language].CONTENT_HOME_PAGE.CONTENT_BODY.BODY_WHAT_I_DO_PART_II}
                        <br /> 
                    </p>
                </div>
            </section>
        </Fragment>
    }

    return (
        <main>
            <section>
                <Banner></Banner>
            </section>
            <article id="why-I-do" className="container-fluid why-I-do">{estructureWhyIdo()}</article>
            <article id="how-I-do" className="how-I-do">{estructureHowIdo()}</article>
            <article id="what-I-do" className="what-I-do">{estructureWhatIdo()}</article>
        </main>
    )
}

export default Home;
