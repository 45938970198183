import React, { Fragment, useState } from "react";
import { withParams } from "../../utils/SharedUtils";
const StudyItem = (props) => {
    const [study, setStudy] = useState(props.study ? props.study : null);
    return (
        <Fragment>
            {
                study ?
                    (
                        <li className="experience__section-container__experience-list" style={{ color: 'var(--primary-color)' }}>
                            <div className="experience__section-container__experience-list__internal-row">
                                <p>
                                    <b>Estudio: </b>
                                </p>
                                <span>
                                    {study.study_name}
                                </span>
                            </div>
                            <div className="experience__section-container__experience-list__internal-row">
                                <p>
                                    <b>Institución: </b>
                                </p>
                                <span className="badge rounded-pill bg-secondary badge-company">
                                    <a target="_blank" href={study.study_institution_web_page}>
                                        {study.study_institution}
                                    </a>
                                </span>
                            </div>
                            <div className="experience__section-container__experience-list__internal-row">
                                <p>
                                    <b>Fecha: </b>
                                </p>
                                <span className="badge rounded-pill bg-secondary badge-date">
                                    {study.study_degree_year ? study.study_degree_year : 'En curso'}
                                </span>
                            </div>
                        </li>
                    )
                    :
                    ''
            }
        </Fragment>
    )
}

export default withParams(StudyItem);