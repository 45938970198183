import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import logo_cc from '../assets/images/logo_cc.svg';
import shirt from '../assets/images/ico_camiseta.svg';
import languages from '../assets/images/ico_idioma.svg';
import '../Styles/Components.scss';
import icons from './Constants/iconsShortCut';
import ReactGA from 'react-ga';
import LanguageContext from "../context/LanguageContext";
import languageFont from '../assets/language';
const Header = () => {
    const { solidIcons, brandIcons } = icons;
    const { language, setLanguage } = useContext(LanguageContext);
    useEffect(() => {
        ReactGA.event({
            category: 'Usuario Registrado',
            action: `Nuevo Usuario: asd`,
        });
    }, []);
    return (
        <header className="header">
            <Link className="header__logo" to={'/'}>
                <img src={logo_cc}></img>
            </Link>
            <ul className="header__menu-large">
                <li className="nav-item" >
                    <Link to={'/'}>
                        {languageFont[language].CONTENT_HEADER.LINKS.HOME}
                    </Link>
                </li>
                <li className="nav-item bordered">
                    <Link to={'/blog'}>
                        {languageFont[language].CONTENT_HEADER.LINKS.BLOG}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'/profile'}>
                        {languageFont[language].CONTENT_HEADER.LINKS.PROFILE}
                    </Link>
                </li>
                {/* <li className="header__menu-large__shirt">
                    <a>
                    {languageFont[language].CONTENT_HEADER.T_SHIRTS}
                    </a>
                    <img src={shirt}></img>
                </li> */}
                <li>
                    <div className="header__menu-large__language">
                        <a className="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={languages} className="img-fluid" />
                            <span>{languageFont[language].CONTENT_HEADER.LINKS.LANGUAGES}</span>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="languageDropdown">
                            <li>
                                <a
                                    className={"dropdown-item" + (language === 'ES' ? ' ACTIVE' : '')}
                                    onClick={() => { setLanguage('ES') }}
                                >
                                    {languageFont[language].CONTENT_HEADER.LINKS.SPANISH}
                                </a>
                            </li>
                            <li>
                                <a
                                    className={"dropdown-item" + (language === 'EN' ? ' ACTIVE' : '')}
                                    onClick={() => { setLanguage('EN') }}
                                >
                                    {languageFont[language].CONTENT_HEADER.LINKS.ENGLISH}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="header__menu-large__social-media">
                        <a target="_blank" href="https://www.linkedin.com/in/carlos-andres-casta%C3%B1eda-osorio-7b652bb3/">
                            <FontAwesomeIcon className="" icon={brandIcons['faLinkedin']} />
                        </a>
                        <a className="ms-md-2" target="_blank" href='https://twitter.com/CarlosCasta23'>
                            <FontAwesomeIcon icon={brandIcons['faTwitter']} />

                        </a>
                        <a className="ms-md-2" target="_blank" href='https://www.instagram.com/carloscasta23/?hl=es-la'>
                            <FontAwesomeIcon icon={brandIcons['faInstagram']} />

                        </a>
                    </div>
                </li>

            </ul>
            <FontAwesomeIcon className="header__icon-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLanding" aria-controls="offcanvasLanding" icon={solidIcons['faBars']} />

            <div className="offcanvas offcanvas-top" tabIndex="-1" id="offcanvasLanding" aria-labelledby="offcanvasLandingLabel" data-bs-scroll="true" data-bs-backdrop="false">
                <div className="offcanvas-body">
                    <ul className="nav nav-menu-options flex-column">
                        <li className="nav-item" data-bs-dismiss="offcanvas" >
                            <Link to={'/'}>
                                {languageFont[language].CONTENT_HEADER.LINKS.HOME}
                            </Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas" >
                            <Link to={'/blog'}>
                                {languageFont[language].CONTENT_HEADER.LINKS.BLOG}
                            </Link>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas" >
                            <Link to={'/profile'}>
                                {languageFont[language].CONTENT_HEADER.LINKS.PROFILE}
                            </Link>
                        </li>
                        <li className="nav-item" >
                            {/* <div className="header__menu-large__language"> */}
                            <a className="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={languages} className="img-fluid" />
                                <span>{languageFont[language].CONTENT_HEADER.LINKS.LANGUAGES}</span>
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="languageDropdown">
                                <li>
                                    <a
                                        data-bs-dismiss="offcanvas"
                                        className={"dropdown-item" + (language === 'ES' ? ' ACTIVE' : '')}
                                        onClick={() => { setLanguage('ES') }}
                                    >
                                        {languageFont[language].CONTENT_HEADER.LINKS.SPANISH}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        data-bs-dismiss="offcanvas"
                                        className={"dropdown-item" + (language === 'EN' ? ' ACTIVE' : '')}
                                        onClick={() => { setLanguage('EN') }}
                                    >
                                        {languageFont[language].CONTENT_HEADER.LINKS.ENGLISH}
                                    </a>
                                </li>
                            </ul>
                            {/* </div> */}
                        </li>
                        {/* <li className="nav-item shirt-item" data-bs-dismiss="offcanvas" >
                            <a>
                                {languageFont[language].CONTENT_HEADER.T_SHIRTS}
                            </a>
                            <img className="nav-item--shirt-img" src={shirt}></img>
                        </li> */}
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header;
