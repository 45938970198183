import React, { Fragment, useEffect, useState } from "react";
import StudyItem from "../../components/Profile/StudyItem";
const MockStudies = [
    {
        "id": 1,
        "study_name": "Bachiller técnico en sistemas de información",
        "study_obtained_title": "Bachiller técnico en sistemas de información",
        "study_is_finished": true,
        "study_degree_year": 2010,
        "study_institution": "Colegio nuestra señora de fátima",
        "study_institution_web_page": "https://www.bienestarpolicia.gov.co/index.php?idcategoria=2605",
        "study_city": "Manizales",
        "study_state": "Caldas",
        "study_country": "Colombia",
        "study_type": "formal"
    },
    {
        "id": 2,
        "study_name": "Ingeniería en sistemas y computación",
        "study_obtained_title": "Ingeniero en sistemas y computación",
        "study_is_finished": true,
        "study_degree_year": 2016,
        "study_institution": "Universidad de Caldas",
        "study_institution_web_page": "https://ingenierias.ucaldas.edu.co/oferta-academica/ingenieria-de-sistemas-y-computacion/",
        "study_city": "Manizales",
        "study_state": "Caldas",
        "study_country": "Colombia",
        "study_type": "formal"
    },
    {
        "id": 3,
        "study_name": "Maestría en ingeniería computacional",
        "study_obtained_title": "Magister en ingeniería computacional",
        "study_is_finished": true,
        "study_degree_year": 2016,
        "study_institution": "Universidad de Caldas",
        "study_institution_web_page": "https://ingenierias.ucaldas.edu.co/oferta-academica/maestria-en-ingenieria-computacional/",
        "study_city": "Manizales",
        "study_state": "Caldas",
        "study_country": "Colombia",
        "study_type": "formal"
    },
    {
        "id": 4,
        "study_name": "Educación complementaria en Platzi",
        "study_obtained_title": null,
        "study_is_finished": false,
        "study_degree_year": null,
        "study_institution": "Platzi",
        "study_institution_web_page": "https://platzi.com/p/ccasta23/",
        "study_city": "Manizales",
        "study_state": "Caldas",
        "study_country": "Colombia",
        "study_type": "complementary"
    },
    {
        "id": 5,
        "study_name": "Educación complementaria en Pluralsight",
        "study_obtained_title": null,
        "study_is_finished": false,
        "study_degree_year": null,
        "study_institution": "Pluralsight",
        "study_institution_web_page": "https://app.pluralsight.com/profile/carlosandres-castane",
        "study_city": "Manizales",
        "study_state": "Caldas",
        "study_country": "Colombia",
        "study_type": "complementary"
    }
];

const Studies = () => {
    const [studies, setStudies] = useState(null);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = () => {
        setStudies(MockStudies);
    }

    return (
        <ul>
            {
                studies && studies.map((study) => {
                    return <StudyItem key={study.id} study={study}></StudyItem>
                })
            }
        </ul>
    )
}

export default Studies;