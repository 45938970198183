import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import logo_cc from '../assets/images/logo_cc.svg';
import icons from '../components/Constants/iconsShortCut';
import LanguageContext from "../context/LanguageContext";
import languageFont from '../assets/language';
const Footer = () => {
    const { brandIcons } = icons;
    const {language} = useContext(LanguageContext); 
    return (
        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6 article order-0 order-md-1">
                        <h6>{languageFont[language].CONTENT_FOOTER.TITLES.INFORMATION}</h6>
                        <p>{languageFont[language].CONTENT_FOOTER.CONTENT_BODY.BODY_INFORMATION_PART_I}  <br/> {languageFont[language].CONTENT_FOOTER.CONTENT_BODY.BODY_INFORMATION_PART_II}</p>
                    </div>
                    <div className="col-6 col-md-3 logo order-1 order-md-0">
                        <img className="header--logo" src={logo_cc}></img>
                    </div>
                    <div className="col-6 col-md-3 social-media order-2 order-md-2">
                        <a target="_blank" href="https://www.linkedin.com/in/carlos-andres-casta%C3%B1eda-osorio-7b652bb3/">
                            <FontAwesomeIcon className="" icon={brandIcons['faLinkedin']} />
                        </a>
                        <a target="_blank" href='https://twitter.com/CarlosCasta23'>
                            <FontAwesomeIcon className="ms-2" icon={brandIcons['faTwitter']} />

                        </a>
                        <a target="_blank" href='https://www.instagram.com/carloscasta23/?hl=es-la'>
                            <FontAwesomeIcon className="ms-2" icon={brandIcons['faInstagram']} />

                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
