import React, { useEffect, useState } from "react";
import { withParams } from "../../utils/SharedUtils";
import first from '../../assets/images/first-entry.jpeg';
import second from '../../assets/images/second-entry.jpeg';
import third from '../../assets/images/third-entry.jpeg';
import '../../Styles/Blog.scss';
import { CopyBlock, dracula } from "react-code-blocks";

// let personList = `First_Name: John, Last_Name: Doe
// First_Name: Jane, Last_Name: Smith`;
// let personList2 = `<div class="internal-code">
//     <code>
//         git init
//     </code>
// </div>`;
// let match2 = regexpNames2.exec(personList2as);
// console.log(match2);

// let match = regexpNames.exec(personList);
// console.log(match);

// do {
//   console.log(`Hola ${match.groups.firstname} ${match.groups.lastname}`);
// } while((match = regexpNames.exec(personList)) !== null);
const EntryDetail = (props) => {
  const [language, changeLanguage] = useState("jsx");
  const [languageDemo, changeDemo] = useState('');
  const [lineNumbers, toggleLineNumbers] = useState(true);
  const sections = [
    ` <div class="">
    <h1
      id="6484"
      class="pw-post-title gu gv gw bd gx gy gz ha hb hc hd he hf hg hh hi hj hk hl hm hn ho hp hq hr hs bi"
      data-selectable-paragraph=""
    >
      <strong class="ak ">¿Por qué deberías aprender GIT?</strong>
    </h1>
  </div>`,
    `<figure class="ec ee hu hv hw hx dy dz paragraph-image">
  <div role="button" tabindex="0" class="hy hz di ia bf ib">
    <div class="dy dz ht">
      <picture
        ><source
          srcset="
            https://miro.medium.com/max/640/1*-E6PvI_epYHZHA5CKsntJg.webp   640w,
            https://miro.medium.com/max/720/1*-E6PvI_epYHZHA5CKsntJg.webp   720w,
            https://miro.medium.com/max/750/1*-E6PvI_epYHZHA5CKsntJg.webp   750w,
            https://miro.medium.com/max/786/1*-E6PvI_epYHZHA5CKsntJg.webp   786w,
            https://miro.medium.com/max/828/1*-E6PvI_epYHZHA5CKsntJg.webp   828w,
            https://miro.medium.com/max/1100/1*-E6PvI_epYHZHA5CKsntJg.webp 1100w,
            https://miro.medium.com/max/1400/1*-E6PvI_epYHZHA5CKsntJg.webp 1400w
          "
          sizes="(min-resolution: 4dppx) and (max-width: 700px) 50vw, (-webkit-min-device-pixel-ratio: 4) and (max-width: 700px) 50vw, (min-resolution: 3dppx) and (max-width: 700px) 67vw, (-webkit-min-device-pixel-ratio: 3) and (max-width: 700px) 65vw, (min-resolution: 2.5dppx) and (max-width: 700px) 80vw, (-webkit-min-device-pixel-ratio: 2.5) and (max-width: 700px) 80vw, (min-resolution: 2dppx) and (max-width: 700px) 100vw, (-webkit-min-device-pixel-ratio: 2) and (max-width: 700px) 100vw, 700px"
          type="image/webp" />
        <source
          data-testid="og"
          srcset="
            https://miro.medium.com/max/640/1*-E6PvI_epYHZHA5CKsntJg.png   640w,
            https://miro.medium.com/max/720/1*-E6PvI_epYHZHA5CKsntJg.png   720w,
            https://miro.medium.com/max/750/1*-E6PvI_epYHZHA5CKsntJg.png   750w,
            https://miro.medium.com/max/786/1*-E6PvI_epYHZHA5CKsntJg.png   786w,
            https://miro.medium.com/max/828/1*-E6PvI_epYHZHA5CKsntJg.png   828w,
            https://miro.medium.com/max/1100/1*-E6PvI_epYHZHA5CKsntJg.png 1100w,
            https://miro.medium.com/max/1400/1*-E6PvI_epYHZHA5CKsntJg.png 1400w
          "
          sizes="(min-resolution: 4dppx) and (max-width: 700px) 50vw, (-webkit-min-device-pixel-ratio: 4) and (max-width: 700px) 50vw, (min-resolution: 3dppx) and (max-width: 700px) 67vw, (-webkit-min-device-pixel-ratio: 3) and (max-width: 700px) 65vw, (min-resolution: 2.5dppx) and (max-width: 700px) 80vw, (-webkit-min-device-pixel-ratio: 2.5) and (max-width: 700px) 80vw, (min-resolution: 2dppx) and (max-width: 700px) 100vw, (-webkit-min-device-pixel-ratio: 2) and (max-width: 700px) 100vw, 700px" />
        <img
          alt=""
          class="bf ic id c"
          width="700"
          height="394"
          loading="eager"
          role="presentation"
          src="https://miro.medium.com/max/1400/1*-E6PvI_epYHZHA5CKsntJg.png"
      /></picture>
    </div>
  </div>
  </figure>`,
    `<p
  id="bc44"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Si te encuentras entrando en el mundo del desarrollo de software, una de las preguntas más frecuentes será ¿Por qué debo aprender git?,
  en este <em class="jc">post</em> encontrarás la respuesta.
  </p>
  <p
  id="103a"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Colaborar en el desarrollo de software de una aplicación con amigos o compañeros de trabajo es una labor demandante, cada persona avanza
  en el trabajo que le corresponde y, posteriormente, unir los cambios provocados a la base del código, puede tornarse en trabajo largo y
  tedioso. Para solucionar este problema existen los software de control de versiones (VCS), estos son herramientas de software que
  monitorizan y gestionan cambios en un sistema de archivos, así varias personas pueden trabajar sobre una misma base de código, hacer sus
  cambios y a través del VCS unir sus resultados.
  </p>
  <p
  id="da20"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Dentro de las opciones de VCS se encuentra Git. GIT es un software de control de versiones distribuido ampliamente usado en la
  industria. En el
  <a
    class="ae jd"
    href="https://survey.stackoverflow.co/2022/#section-version-control-version-control-systems"
    rel="noopener ugc nofollow"
    target="_blank"
    ><em class="jc">Stackoverflow survey de 2021</em></a
  >
  más del 90% de los desarrolladores de software profesionales encuestados usaban la herramienta mientras que en el
  <a
    class="ae jd"
    href="https://survey.stackoverflow.co/2022/#section-version-control-version-control-systems"
    rel="noopener ugc nofollow"
    target="_blank"
    ><em class="jc">Stackoverflow survey de 2022</em></a
  >, el 96.65% de los mismos respondieron usarla.
  </p>
  <p
  id="7073"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  GIT es un software multiplataforma y puede ser usado en sistemas operativos como Windows, Linux y MacOS. En la
  <a class="ae jd" href="https://git-scm.com" rel="noopener ugc nofollow" target="_blank"><em class="jc">página oficial</em></a> se puede
  encontrar la
  <a class="ae jd" href="https://git-scm.com/downloads" rel="noopener ugc nofollow" target="_blank">guía de instalación de Git</a>.
  </p>`,
    `<div class="ab cl je jf gd jg" role="separator">
  <span class="jh bw bk ji jj jk"></span><span class="jh bw bk ji jj jk"></span><span class="jh bw bk ji jj"></span>
  </div>`,
    `<p
  id="9d28"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  En este post repasaremos comandos básicos de esta útil herramienta que te podrán servir para iniciar o reforzar tus conocimientos en
  esta. Mi recomendación para aquellos que se encuentran iniciando es el uso de la terminal, esta última será una herramienta vital en
  este campo y es importante conocerla y enfrentarse a ella tan pronto como sea posible.
  </p>
  <p
  id="45b6"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Antes de iniciar con el proceso técnico, es importante abordar el concepto de repositorio. Un repositorio de Git es un almacenamiento
  virtual de tu proyecto. Te permite guardar versiones del código a las que puedes acceder cuando lo necesites. En términos más prácticos
  en nuestro computador será una carpeta con archivos a los cuales se les realiza rastreo de los cambios.
  </p>
  <p
  id="3af5"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Para convertir una carpeta común de nuestro computador en un repositorio, accedemos a ella desde la terminal, y a continuación
  ejecutamos el comando <em class="jc">git init</em>. Al realizar esta acción, en nuestra carpeta se crea una subcarpeta
  <em class="jc">.git</em> que contendrá el historial completo de cambios de los archivos de nuestra carpeta.
  </p>`,
    `<div language="bash"
  class="internal-code"
  >cd carpeta 
git init</div>`,
    `    <p
  id="9ab4"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Luego de tener nuestro repositorio inicializado, es importante entender por los estados que pueden pasar nuestros archivos. En la
  siguiente gráfica se puede evidenciar los posibles estados y las opciones disponibles para pasar entre estados.
  </p>
  <figure class="jl jm jn jo ek hx dy dz paragraph-image">
  <div role="button" tabindex="0" class="hy hz di ia bf ib">
    <div class="dy dz ka">
      <picture
        ><source
          srcset="
            https://miro.medium.com/max/640/1*qcaVb02ZqHfRSNgSof4lQg.webp   640w,
            https://miro.medium.com/max/720/1*qcaVb02ZqHfRSNgSof4lQg.webp   720w,
            https://miro.medium.com/max/750/1*qcaVb02ZqHfRSNgSof4lQg.webp   750w,
            https://miro.medium.com/max/786/1*qcaVb02ZqHfRSNgSof4lQg.webp   786w,
            https://miro.medium.com/max/828/1*qcaVb02ZqHfRSNgSof4lQg.webp   828w,
            https://miro.medium.com/max/1100/1*qcaVb02ZqHfRSNgSof4lQg.webp 1100w,
            https://miro.medium.com/max/1400/1*qcaVb02ZqHfRSNgSof4lQg.webp 1400w
          "
          sizes="(min-resolution: 4dppx) and (max-width: 700px) 50vw, (-webkit-min-device-pixel-ratio: 4) and (max-width: 700px) 50vw, (min-resolution: 3dppx) and (max-width: 700px) 67vw, (-webkit-min-device-pixel-ratio: 3) and (max-width: 700px) 65vw, (min-resolution: 2.5dppx) and (max-width: 700px) 80vw, (-webkit-min-device-pixel-ratio: 2.5) and (max-width: 700px) 80vw, (min-resolution: 2dppx) and (max-width: 700px) 100vw, (-webkit-min-device-pixel-ratio: 2) and (max-width: 700px) 100vw, 700px"
          type="image/webp" />
        <source
          data-testid="og"
          srcset="
            https://miro.medium.com/max/640/1*qcaVb02ZqHfRSNgSof4lQg.png   640w,
            https://miro.medium.com/max/720/1*qcaVb02ZqHfRSNgSof4lQg.png   720w,
            https://miro.medium.com/max/750/1*qcaVb02ZqHfRSNgSof4lQg.png   750w,
            https://miro.medium.com/max/786/1*qcaVb02ZqHfRSNgSof4lQg.png   786w,
            https://miro.medium.com/max/828/1*qcaVb02ZqHfRSNgSof4lQg.png   828w,
            https://miro.medium.com/max/1100/1*qcaVb02ZqHfRSNgSof4lQg.png 1100w,
            https://miro.medium.com/max/1400/1*qcaVb02ZqHfRSNgSof4lQg.png 1400w
          "
          sizes="(min-resolution: 4dppx) and (max-width: 700px) 50vw, (-webkit-min-device-pixel-ratio: 4) and (max-width: 700px) 50vw, (min-resolution: 3dppx) and (max-width: 700px) 67vw, (-webkit-min-device-pixel-ratio: 3) and (max-width: 700px) 65vw, (min-resolution: 2.5dppx) and (max-width: 700px) 80vw, (-webkit-min-device-pixel-ratio: 2.5) and (max-width: 700px) 80vw, (min-resolution: 2dppx) and (max-width: 700px) 100vw, (-webkit-min-device-pixel-ratio: 2) and (max-width: 700px) 100vw, 700px" />
        <img
          alt=""
          class="bf ic id c"
          width="700"
          height="292"
          loading="lazy"
          role="presentation"
          src="https://miro.medium.com/max/1400/1*qcaVb02ZqHfRSNgSof4lQg.png"
      /></picture>
    </div>
  </div>
  </figure>
  <p
  id="4f7d"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  A continuación, defino cada estado:
  </p>
  <p
  id="bddd"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  · <strong class="ig gx">Untracked</strong>: Archivos que se encuentran dentro de nuestro repositorio (carpeta) y aún no les estamos
  realizando seguimiento.
  </p>
  <p
  id="3137"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  · <strong class="ig gx">Unmodified</strong>: Archivos que ya conoce el repositorio y se encuentran en la última versión conocida.
  </p>
  <p
  id="bd82"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  · <strong class="ig gx">Modified</strong>: Archivos que ya conoce el repositorio y tienen cambios con respecto a la última versión
  conocida.
  </p>
  <p
  id="34d5"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  · <strong class="ig gx">Staged</strong>: Archivos nuevos o que fueron modificados que se desean incluir en la nueva versión de nuestro
  repositorio.
  </p>
  <p
  id="fd87"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Como acabamos de inicializar nuestro repositorio, este no conoce ningún archivo, por lo cual todo lo que agreguemos entrará en estado
  “<strong class="ig gx"><em class="jc">Untracked</em></strong
  >”.
  </p>
  <p
  id="fd6d"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Para crear la primera versión de nuestro repositorio añadimos un nuevo archivo dentro de la carpeta, para efectos prácticos de este post
  no es relevante el archivo creado, por lo que crearemos un archivo llamado README.md que describe la finalidad del repositorio. El
  contenido del archivo se muestra a continuación:
  </p>`,
    `<div language="markdown"
    class="internal-code"
    ># ¿Por qué es importante aprender Git?\n\n## Comandos útiles\n- git init\n- git add\n- git commit\n- git status\n- git log\n- git config</div>`,
    `<p
  id="8d95"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Posterior a la creación del archivo, podemos validar el estado de nuestro repositorio con el comando git status. Este comando puede ser
  utilizado en todo momento y nos muestra el estado de los archivos que tenemos en nuestro repositorio, especialmente de aquellos que se
  encuentran en los estados “<em class="jc">Untracked</em>”, “<em class="jc">Modified</em>” y “<em class="jc">Staged</em>”. En nuestro
  caso la salida de este comando nos muestra que ahora nuestro repositorio tiene un nuevo archivo al que aún no se le está realizando
  seguimiento (“<em class="jc">Untracked</em>”), lo que haremos a continuación será a través de git incluir el archivo en el repositorio e
  iniciar a rastrear todos los cambios que este tenga a lo largo del tiempo. Para realizar esta acción usamos el comando
  <em class="jc">git add</em>.
  </p>`,
    `<div language="jsx"
    class="internal-code">
      <main>
          <section id="blog" className="pb-4">
              <div className="blog__header-image">
                  <img src={header_blog} alt={languageFont[language].IMAGE_ALT.HEADER_BLOG}></img>
              </div>
              {
                  blogList.map((blogEntry, index) => {
                      return (
                          <Link key={index} className="entry-list" to={'/blog/' + blogEntry.slug}>
                              <EntryItem InfoEntry={blogEntry} currentIndex={index}>
                              </EntryItem>
                          </Link>
                      )
                  })
              }
          </section>
      </main>
  </div>`,
    `<p
  id="84fe"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  <strong class="ig gx">Nota</strong>: En este caso agregamos un solo archivo, por ello en el comando colocamos el nombre del archivo, sin
  embargo si tenemos más de un archivo en los estados “Untracked” o “Modified” podemos agregarlos todos con el comando
  <strong class="ig gx"><em class="jc">git add .</em></strong>
  </p>
  <p
  id="1dd3"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Ahora todos los archivos que agregamos con el comando anterior se encuentran en estado “<em class="jc">Staging</em>”. Pero ¿Qué
  significa este estado? El área de “<em class="jc">staging</em>” se puede ver como un limbo donde nuestros archivos están por ser
  enviados al repositorio (Ser parte de la nueva versión) o ser regresados a la carpeta del proyecto (A su estado anterior).
  </p>
  <p
  id="1895"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  En este estado, lo único que nos queda por realizar es confirmar los cambios que tenemos en el área de “<em class="jc">Staging</em>” y
  así generar una nueva versión de nuestro proyecto que será parte del historial y podremos regresar a esta cuando consideremos necesario.
  Para aportar en esta labor encontramos el comando <em class="jc">git commit</em>, este comando permite confirmar los cambios, crear un
  nuevo registro en las confirmaciones de nuestro repositorio y pasar todos los archivos del estado “<em class="jc">Staged</em>” a “<em
    class="jc"
    >Unmodified</em
  >”.
  </p>`,
    `<div language="markdown"
    class="internal-code"> git commit -m "Primera confirmación del repositorio. Se agrega el archivo README.md"</div>`,
    `<p
  id="c8bd"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  En este comando agregamos la bandera <em class="jc">-m</em>, esta nos permite colocar un mensaje a nuestra nueva versión del repositorio
  para poder recordar la funcionalidad de esta confirmación en el futuro. Se recomienda agregar un texto descriptivo que sea claro y
  muestre los cambios realizados sobre el repositorio.
  </p>
  <p
  id="55b0"
  class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
  data-selectable-paragraph=""
  >
  Con esto ya hemos probado comandos básicos de la herramienta y puedes explorar muchos más que te dejo a continuación:
  </p>`,
    `<div language="markdown"
    class="internal-code"
  >git status \ngit log\ngit log –oneline\ngit commit -am &lt;Mensaje&gt;\ngit branch</div>`,
    `<div class="ab cl je jf gd jg" role="separator">
  <span class="jh bw bk ji jj jk"></span><span class="jh bw bk ji jj jk"></span><span class="jh bw bk ji jj"></span>
  </div>`,
    `<div class="gp gq gr gs gt">
  <h1 id="a5c0" class="kb jv gw bd kc kd ke kf kg kh ki kj kk kl km kn ko kp kq kr ks kt ku kv kw kx bi" data-selectable-paragraph="">
    <strong class="ak">Conclusión</strong>
  </h1>
  <p
    id="88f9"
    class="pw-post-body-paragraph ie if gw ig b ih ky ij ik il kz in io ip la ir is it lb iv iw ix lc iz ja jb gp bi"
    data-selectable-paragraph=""
  >
    En conclusión, si quieres trabajar en un equipo en el mundo del desarrollo de Software es muy probable que debas aprender el software de
    control de versiones más utilizado en el medio llamado <em class="jc">git</em>, que como viste en este post es ampliamente utilizado y
    fácil de usar.
  </p>
  <p
    id="a3cb"
    class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
    data-selectable-paragraph=""
  >
    Puedes encontrar la guía completa de git en su página oficial.
  </p>
  <p
    id="f331"
    class="pw-post-body-paragraph ie if gw ig b ih ii ij ik il im in io ip iq ir is it iu iv iw ix iy iz ja jb gp bi"
    data-selectable-paragraph=""
  >
    Para más contenido como este puedes visitar mi página oficial
    <a class="ae jd" href="https://ccastaneda.co" rel="noopener ugc nofollow" target="_blank"><em class="jc">ccastaneda.co</em></a> o
    seguirme en mis redes sociales.
  </p>
  </div>`
  ]

  const { slug } = props.params;
  const [blog, setBlog] = useState(null);
  const [blogList, setBlogList] = useState([
    {
      id: 1,
      title: "Lorem Ipsum is simply dummy",
      author: "Carlos Andrés Castañeda",
      author_profile_url: "https://ccastaneda.co",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of...",
      large_description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets.",
      image_url: first,
      date: "08/07/2020",
      commentary_count: 8,
      slug: "lorem-ipsum-is-simply-dummy1",
      order: 2
    },
    {
      id: 2,
      title: "Lorem Ipsum is simply dummy",
      author: "Carlos Andrés Castañeda",
      author_profile_url: "https://ccastaneda.co",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of...",
      large_description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets.",
      image_url: second,
      date: "08/07/2020",
      commentary_count: 36,
      slug: "lorem-ipsum-is-simply-dummy2",
      order: 3
    },
    {
      id: 3,
      title: "Lorem Ipsum is simply dummy",
      author: "Carlos Andrés Castañeda",
      author_profile_url: "https://ccastaneda.co",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of...",
      large_description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets.",
      image_url: third,
      date: "08/07/2020",
      commentary_count: 0,
      slug: "lorem-ipsum-is-simply-dummy3",
      order: 1
    }
  ]);

  useEffect(() => {
    if (slug) {
      setBlog(blogList.find(blogItem => blogItem.slug == slug));
    };

  }, []);

  const destructureBlog = () => {
    if (!sections || sections.length === 0)
      return null
    return sections.map(section => {

      if (section.includes('internal-code')) {
        let element = document.getElementById('prueba');
        if (element) {
          element.innerHTML = section;
          let children = element.childNodes;
          const childrenLanguage = children[0].getAttribute('language');
          const childrenText = children[0].innerHTML;
          return (
            <CopyBlock
              language={childrenLanguage}
              text={childrenText}
              codeBlock
              theme={dracula}
              showLineNumbers={false}
            />
          )
        }
      } else {
        return <div dangerouslySetInnerHTML={{ __html: section }}></div>;
      }
    });
  }

  return <main className="entry-detail">
    {/* {blog ?
      <div className="container px-5 pt-4">
        <header className="row mb-3">
          <h1 className="col-12 ">{blog.title}</h1>
          <h6><span>{blog.author}</span> - {blog.date}</h6>
        </header>
        <div className="clearfix">
          <img src={blog.image_url} className="col-md-6 float-md-start mb-3 ms-md-3 p-5" alt="..." />
          <p className="col-12">{blog.large_description}</p>
        </div>
      </div>


      : ''
    } */}
    <div id="temporal-container"></div>
    <div className="container">
      <div className="col-12">
        {destructureBlog()}
      </div>
    </div>

  </main>
}

export default withParams(EntryDetail);