import React, { Fragment, useState } from "react";
import { withParams } from "../../utils/SharedUtils";
import '../../Styles/Profile.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import icons from '../../components/Constants/iconsShortCut';
const SkillBranch = ({ dataSkill, onOpenSonView, isLeaf = false }) => {
    const [skill, setSkill] = useState(dataSkill ?? null);
    const isTreeBase = skill.fk_skill === null;
    const hasRating = skill.skill_rating && skill.has_rating
    const ratingScore = (skill.skill_rating ?? '0') + '0%';
    const { solidIcons } = icons;
    const isOpenedBranch = skill && skill.is_open;
    const ValidateRating = () => {
        if (!hasRating) return null;
        else return (
            <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: ratingScore }} aria-valuenow={skill.skill_rating + '0%'} aria-valuemin="0" aria-valuemax="10"></div>
            </div>
        );
    }

    const ValidateLeaf = () => {
        if (isLeaf) return null;
        if(isOpenedBranch) {
            return (
                <FontAwesomeIcon icon={solidIcons['faAngleUp']} onClick={() => onOpenSonView(skill)} />
            ); 
        }
        return (
            <FontAwesomeIcon icon={solidIcons['faAngleDown']} onClick={() => onOpenSonView(skill)} />
        );
    }
    return (<Fragment>
        {
            skill ?
                (
                    <div className="self-accordion__item__selector">
                        <h3
                            className={(isTreeBase ? 'title-' : '') + 'label-skill'}
                            onClick={() => onOpenSonView(skill)}
                            style={{ cursor: 'pointer' }}
                        >
                            {skill.skill_name}
                            {ValidateLeaf()}
                            {ValidateRating()}
                        </h3>
                    </div>
                )
                :
                ''
        }
    </Fragment>)

}

export default withParams(SkillBranch);