import React, { Fragment, useState } from "react";
import { withParams } from "../../utils/SharedUtils";
const AdditionalProjectItem = (props) => {
    const [additionalProject, setAdditionalProject] = useState(props.additionalProject ? props.additionalProject : null);
    return (
        <Fragment>
            {
                additionalProject ?
                    (
                        <li className="experience__section-container__experience-list" style={{ color: 'var(--primary-color)' }}>
                            <div className="experience__section-container__experience-list__internal-row">
                                <p>
                                    <b>Proyecto: </b>
                                </p>
                                <span>
                                    {additionalProject.additional_project_name}
                                </span>
                            </div>
                            <div className="experience__section-container__experience-list__internal-row">
                                <p>
                                    <b>Fecha: </b>
                                </p>
                                <span className="badge rounded-pill bg-secondary badge-date">
                                    {additionalProject.additional_project_creation_year}
                                </span>
                            </div>
                        </li>
                    )
                    :
                    ''
            }
        </Fragment>
    )
}

export default withParams(AdditionalProjectItem);